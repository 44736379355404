import { LoginCallback } from '@okta/okta-react';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { CCircularProgress } from 'uikit';

import { OktaErrorComponent } from 'components/OktaErrorComponent';

function LoginRedirectWrapper() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');

  return code ? (
    <LoginCallback loadingElement={<CCircularProgress isCentered />} errorComponent={OktaErrorComponent} />
  ) : (
    <Navigate to="/" />
  );
}

export default LoginRedirectWrapper;
