import type { UserClaims } from '@okta/okta-auth-js';
import { type LoginCallback, useOktaAuth } from '@okta/okta-react';
import { type ComponentProps, type FC, useEffect, useState } from 'react';

import { postData } from 'api/fetchApi';
import { useMount } from 'hooks/useMount';
import { sendErrorAnalytics } from 'utils/analytics';
import { logger } from 'utils/logger';

import NoAccessScreen from './NoAccessScreen';

const ErrorComponent: FC<ComponentProps<NonNullable<ComponentProps<typeof LoginCallback>['errorComponent']>>> = ({
  error,
}) => {
  const [user, setUser] = useState<UserClaims | undefined>();
  const { oktaAuth, authState } = useOktaAuth();
  useMount(() => {
    (async () => {
      await postData('/users/not-assigned-user-log', error);
    })();
  });
  useEffect(() => {
    if (authState?.accessToken)
      oktaAuth
        .getUser()
        .then(setUser)
        .catch((error) => {
          logger.error('Failed to load Okta user', error);
          sendErrorAnalytics('promise_catch', error);
        });
  }, [authState, oktaAuth]);

  return <NoAccessScreen user={user} />;
};

export default ErrorComponent;
