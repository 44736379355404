import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { CCircularProgress } from 'uikit';

export const RequiredAuth: React.FC<{ onAuthenticated?: () => void }> = ({ onAuthenticated }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const logicBeenExecutedRef = useRef(false);
  const authCheckWasExecuted = useRef(false);
  useEffect(() => {
    if (!authState || logicBeenExecutedRef.current) {
      if (authCheckWasExecuted.current)
        onAuthenticated?.(); /* this needed because *authCheckWasExecuted* is dependency of a hook */
      return;
    }
    authCheckWasExecuted.current = true;
    if (!authState?.isAuthenticated) {
      logicBeenExecutedRef.current = true;
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    } else {
      onAuthenticated?.();
    }
  }, [oktaAuth, authState, onAuthenticated]);

  if (!authState || !authState.isAuthenticated) {
    return <CCircularProgress isCentered />;
  }

  return <Outlet />;
};
export default RequiredAuth;
