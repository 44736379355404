import 'utils/polyfills';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { initNewRelic } from 'newRelic';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import AppRoutes from 'routes/AppRoutes';
import { newTheme } from 'uikit';

import { IntlProviderWrapper } from 'components/contexts';
import { GlobalStyles } from 'components/GlobalStyles';
import { initializeStore } from 'store/store';
import { initServices } from 'utils/amplitude';
import { sendAppLoadAnalytics } from 'utils/analytics';

initServices();
initNewRelic();
sendAppLoadAnalytics();

const App: React.FC = () => {
  const store = initializeStore();

  return (
    <HelmetProvider>
      <Provider store={store}>
        <IntlProviderWrapper>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={newTheme}>
              <GlobalStyles />
              <React.StrictMode>
                <AppRoutes />
              </React.StrictMode>
            </ThemeProvider>
          </StyledEngineProvider>
        </IntlProviderWrapper>
      </Provider>
    </HelmetProvider>
  );
};
export default App;
