import { Security } from '@okta/okta-react';
import React, { lazy, Suspense, useCallback } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { oktaAuth } from 'routes/oktaAuth';
import SecuredRoutes from 'routes/SecuredRoutes';

import RequiredAuth from 'components/RequiredAuth';
import NoMatch from 'features/NoMatch';
import { useScreenTimeAnalytics, useSendErrorAnalytics } from 'hooks';

import GlobalErrorBoundary from './GlobalErrorBoundary';
import LoginRedirectWrapper from './LoginRedirectWrapper';

export const withLazy = <T extends Parameters<typeof lazy>[0]>(importFn: T) => {
  const LazyComponent = lazy(importFn);

  return (props: React.ComponentProps<Awaited<ReturnType<T>>['default']>) => (
    <Suspense fallback={null}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

const OktaActivate = withLazy(() => import('../components/Auth/OktaActivate'));

if (!window.location.href.startsWith('https://co-op.')) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window['_dbg_oktaAuth'] = oktaAuth;
}

const winLocation = window.location,
  windowLocationOrigin = winLocation.origin;

function AppRoutes() {
  const navigate = useNavigate();
  const restoreOriginalUri = useCallback(
    async (_: unknown, url: string) => navigate(url.replace(windowLocationOrigin, ''), { replace: true }),
    [navigate]
  );
  const location = useLocation();
  const isOktaActivation = () => {
    return location.pathname.includes('activate/');
  };
  useSendErrorAnalytics();
  useScreenTimeAnalytics();
  return (
    <GlobalErrorBoundary>
      <Routes>
        <Route path="/activate/:hash" element={<OktaActivate />} />
        <Route path="/" element={null} />
      </Routes>
      {!isOktaActivation() && (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Routes>
            <Route path="/login/callback" element={<LoginRedirectWrapper />} />
            <Route path="*" element={<RequiredAuth />}>
              <Route path="*" element={<SecuredRoutes />} />
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </Security>
      )}
    </GlobalErrorBoundary>
  );
}

export default AppRoutes;
