import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import type { AppTheme } from 'uikit';

import { ROOT_ELEMENT_ID } from 'const/constants/uncategorised';

import App from './App';

// Re-declare the emotion theme to have the properties of the MaterialUiTheme
declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends AppTheme {}
}

const container = document.getElementById(ROOT_ELEMENT_ID);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(container!).render(
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <noscript>{import.meta.env.REACT_APP_IS_PRODUCTION}</noscript>
    <noscript>{import.meta.env.REACT_APP_API_BASE_URI}</noscript>
  </>
);
